import queryString from 'query-string';

export function getQueryStringSearchCollegiats(objParams) {
  return `?${queryString.stringify({
    colegio_id: objParams.colegio_id ? objParams.colegio_id : undefined,
    page: objParams.page !== '' ? objParams.page : undefined
  })}`
}

export function getMsgInputServerColor(objParams, name) {
  return objParams?.data?.status === 'Error' && objParams?.data?.data?.[name]
}

export function getMsgInputColor(objParams, name) {
  return objParams[name]?.required && !objParams[name]?.required?.isOk
}

export function getValuesParams(objValuesSearch) {
  let urlParams = {}

  for (const key of Object.keys(objValuesSearch)) {
    if (objValuesSearch[key] !== '') {
      urlParams[key] = objValuesSearch[key]
    }
  }

  return urlParams
}

export function getURLQuery(objUrlParams){
  return Object.keys(objUrlParams).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(objUrlParams[k])).join('&');
}

export function isNoVerificada(item){
  return item.estado === 'Pendent de revisar' || item.estado === 'Buit'
}

export function isCheckTrue(item){

  if (item === null || item === '' || item === 'false' || item === false)
    return false
  return true
}

export function getSelectByKeys(staticService) {

  const res = Object.keys(staticService).map((key) => {
    return {
      id: key,
      nombre: staticService[key]
    }
  })

  return res
}


export function isBetweenDates(fechaInicio, fechaFin) {

  const fechaActual = new Date();

  const inicio = new Date(fechaInicio);
  const fin = new Date(fechaFin);

  fin.setHours(23, 59, 59, 999);

  return fechaActual >= inicio && fechaActual <= fin;
}